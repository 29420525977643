import * as React from "react";
// import Resolutions from "./../utils/Resolution";
import BaseComponent from "src/engine/ui/BaseComponent";

interface IResolution {
    width: number;
    offset?: number;
    maxFontSize?: number | undefined; //最大的fontsize，解决有些宽屏幕字体超大的情况
    isResolution?: boolean;
    // rem相关，设置后，fontsize最大为100，宽度720，解决宽屏下样式变形等问题
    maxWidth?: number;
    isHscreen?: boolean;
    hWidth?: number;
    resizeScreen?: boolean;
    mobStore?: string;
    minHeight?: number;
}

const per = "RESOLUTION_";

export default class Resolution extends BaseComponent<IResolution> {
    static init = false;
    state = {
        screenWidth: null,
    };
    htmlFs: number;

    componentDidMount() {
        if (!this.htmlFs) {
            this.htmlFs = this.getHtmlFs(document.documentElement);
        }
        this.resize();
        // $(window).resize(this.resize);
        window.onresize = this.resize.bind(this, undefined);

        // window.addEventListener("resize", this.resize);
    }

    UNSAFE_componentWillReceiveProps(nextProps: IResolution) {
        if (
            nextProps.offset !== this.props.offset ||
            // nextProps.width !== this.props.width ||
            nextProps.isHscreen !== this.props.isHscreen ||
            nextProps.maxWidth !== this.props.maxWidth
        ) {
            this.resize(nextProps);
        }
        // if (nextProps.maxFontSize !== this.props.maxFontSize) {
        //     this.resize(nextProps, true);
        // }
    }

    getHtmlFs = (html: Element) => {
        return parseFloat(window.getComputedStyle(html, null)["font-size"]);
    };

    resize = (nextProps?: IResolution, update?: boolean) => {
        const isResolution = window[`${per}noResolution`];
        const maxWidth = window[`${per}useMaxWidth`];
        const mobStore = window[`${per}mobStore`];
        const useScreenWidth = window[`${per}useScreenWidth`];
        const { width: wWidth, hWidth = 1280, minHeight, isHscreen, offset, resizeScreen, maxWidth: propsMaxWidth } = nextProps || this.props;
        if (mobStore === "mi") return;
        const html = document.documentElement;
        let width = wWidth;
        const realwidth = useScreenWidth === true ? window.screen.width : html.offsetWidth;
        if (resizeScreen && isHscreen) {
            // 存在如下两个问题 当设置isHscreen时直接按横屏项目走
            width = hWidth;
            // orientation 这个有兼容性问题；
            // if (window.orientation == 90 || window.orientation == -90) {
            // 新版ios通过屏幕宽度判断横竖屏不可靠
            // if (window.screen.width > window.screen.height) {
            //     width = hWidth;
            // }
            // https://developer.mozilla.org/zh-CN/docs/Web/API/Window/matchMedia
            // window.matchMedia("(orientation: portrait)").matches   判断横屏
        }
        if (!html || !width) return;
        const htmlFs = this.htmlFs;
        let screenWidth;
        if (maxWidth) {
            screenWidth = Math.min(Number(offset || realwidth), width);
        } else if (propsMaxWidth) {
            screenWidth = Math.min(propsMaxWidth as number, width);
        } else {
            screenWidth = Number(offset || realwidth);
        }
        let mediaFs = (100 / width) * screenWidth;
        //进行最小高度判断
        if (minHeight) {
            const realHeight = useScreenWidth === true ? window.screen.height : html.offsetHeight;
            const max = realHeight / mediaFs;
            if (max < minHeight) {
                mediaFs = realHeight / minHeight;
            }
        }

        if (isResolution === true) {
            html.style.fontSize = "50px";
            return;
        }

        if (!this.state.screenWidth || this.state.screenWidth !== screenWidth) {
            this.setState({
                screenWidth,
            });
            // } else if (update && maxFontSize && maxFontSize > 0 && mediaFs > maxFontSize) {
            //     //处理有些内容文字等因为屏幕宽度大，而变得很大的问题，此方式有点问题
            //     mediaFs = maxFontSize;
        } else if (this.state.screenWidth === screenWidth) {
            // return;
        }

        html.style.fontSize = mediaFs + "px"; //根据页面大小算出font-size
        // 解决移动端系统设置字体变大变小后页面rem计算出问题的问题
        if (htmlFs !== 50 && htmlFs !== mediaFs && (htmlFs > mediaFs + 1 || htmlFs < mediaFs - 1)) {
            html.style.fontSize = "100px";
            html.style.fontSize = (mediaFs / htmlFs) * mediaFs + "px";
        }
    };
    render() {
        return <div>{this.props.children}</div>;
    }
}
