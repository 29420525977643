import "src/components/PCPageMgr";import "src/components/Layout";import "src/components/View";import "src/components/Text";import "src/components/ButtonExt";import "src/component/jj/JImage";import "src/component/source/SourceStore";import "src/components/HtmlTrans";import "src/component/script/BaseProps";import "src/component/script/GGEdge";import "src/component/script/ClickScript";import "src/component/script/CallbackScript";import "src/component/script/ClickGoUrl";import "src/components/JList";
        const UILoadingManifest = (key: string) => {
            switch (key) {
                
                default:
                    return null;
            }
        };
        
        UILoadingManifest.PageManifest ={
            index:["PCPage","View","ButtonExt","ClickScript","CallbackScript","Text","JImage","ClickGoUrl"],privacy_protocols:["PCPage","SourceStore","View","HtmlTrans","BaseProps","GGEdge"],regService:["PCPage","SourceStore","View","HtmlTrans","BaseProps","GGEdge"],home:["PCPage","View","ButtonExt","ClickScript","CallbackScript","Text","BaseProps","GGEdge","JList","ClickGoUrl","SourceStore"],
        }

        export default UILoadingManifest;