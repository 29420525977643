
        import Cookie from "src/lib/utils/storage/Cookie";
        
        class GameConfig {
            static gameId = "3003";
            static server = "xstpc";
            static cmsVersion = "3003";
            static publishVersion = '1';
            static cmsSname = "cloudcmsExt";
            static setGameInfo(gameInfo: any) {
                if(GameConfig.server==='cloudcms'){
                    GameConfig.gameId = gameInfo.gameId;
                    Cookie.setItem("gameId", gameInfo.gameId);
                    GameConfig.server = gameInfo.server || GameConfig.server;
                }
            }
            static getActivityId() {
                return GameConfig.cmsSname + "_" + GameConfig.gameId;
            }
        }
        export default GameConfig;