/**
 *
 *
 * @class IPConfig
 */
class IPConfig {
    static ENV = "prod";

    static release = true;

    //是否内网
    static isLocal = false;

    static IP = "/";

    static IP_LINE = "https://wcp.srv.jj.cn/";

    static IP_NOPORT = "/";

    static API = "official-media/user";

    //模板地址
    static IP_TEMP = "//css.cache.jj.cn/activity/";

    //CDN地址
    static CDN = "//css.cache.jj.cn/activity/";
}

export default IPConfig;
